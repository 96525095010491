import axios from 'axios';

const APIService = {

	fetchData: async (endpoint) => {
		try {
			return await axios.get(endpoint);
		} catch(e) {
			//console.error(e);
			//gracefully handle fetch request errors
		}

	}

};

export default APIService;