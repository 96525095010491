import React from 'react';
import './style.scss';

class Pricing extends React.Component {

	render() {
		return (
			<div id="pricing" className='pricing'>
				<table>
					<thead>
						<tr>
							<th scope="col">Presentation</th>
							<th scope="col">Pack Size</th>
							<th scope="col">MA Number(s)</th>
							<th scope="col">Basic NHS Cost</th>
						</tr>
					</thead>
					<tbody>
						{
							this.props.prices && this.props.prices.map((item, i) => {
								return <tr key={i}>
									<td data-label="Presentation:&nbsp;">{item.presentation}</td>
									<td data-label="Pack Size:&nbsp;">{item.packSize}</td>
									<td data-label="MA Number(s):&nbsp;">{item.maNumbers}</td>
									<td data-label="Basic NHS Cost:&nbsp;">{item.nhsCost}</td>
								</tr>;
							})
						}
					</tbody>
				</table>
			</div>

		);
	}
}

Pricing.defaultProps = {
	prices: []
};

export default Pricing;