import React from 'react';
import './style.scss';

class Title extends React.Component {

	render() {
		let isBT = this.props.isBT;
		let displayBlackTriangle = 'blacktriangle-hide';
		if (isBT) { displayBlackTriangle = 'blacktriangle-show'; }
		let btPath = `${process.env.REACT_APP_CDN_BASEPATH}24BT.png`;
		return (
			<div className='title-component app-row-full'>
				<div className="page-title">
					<h1>
						<img className={displayBlackTriangle} src={btPath} 
							width="24" height="24" alt="black triangle" />
						{this.props.title}
					</h1>
				</div>
			</div>
		);
	}
}

Title.defaultProps = {
	title: ''
};

export default Title;