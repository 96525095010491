import React from 'react';
import './style.scss';
import utils from '../../utils';

class PiMetadata extends React.Component {

	render() {

		return (
			<section className='metadata pi'>
				<div className="app-default-margin">
					<div className="app-subtitle">
						{this.props.companyName}
					</div>
					<div>
						<span className="contact-details-link">
							<a href="#contact_details">Contact Details</a>
						</span>
					</div>
				</div>
				<ul className="metadata-list">
					<li>
						<div className="last-updated">
							<div className="app-subtitle">
								<strong>Last Updated</strong>
							</div>
							<div className="dates">
								<div className="row">
									<div>
										<span>SmPC:{'\u00a0'}</span>
									</div>
									<div className="date-value">
										<span> {utils.getLastUpdatedDateFormat(this.props.smpclastUpdated)}</span>
									</div>
								</div>
								<div className="row">
									<div>
										<span>Price:{'\u00a0'}</span>
									</div>
									<div className="date-value">
										<span>{utils.getLastUpdatedDateFormat(this.props.pricelastUpdated)}</span>
									</div>
								</div>

							</div>
						</div>
					</li>
					<li>
						<div className="app-subtitle">
							Active Ingredient
						</div>
						<ul className="item-list">
							{this.props.activeIngredient && this.props.activeIngredient.map((ingredient, index) => {
								return <li key={index}>{ingredient}</li>;
							})}
						</ul>
					</li>
					<li className="spacer">
						<a href="#pricing">Price</a>

					</li>
					<li>
						<div className="app-subtitle">
							Legal Category
						</div>
						<ul className="item-list">
							{this.props.legalCategory && this.props.legalCategory.map((category, index) => {
								return <li key={index}>{category}</li>;
							})}
						</ul>
					</li>
				</ul>
			</section>

		);
	}

}

PiMetadata.defaultProps = {
	companyName: '',
	activeIngredient: [],
	smpclastUpdated: '',
	pricelastUpdated: '',
	legalCategory: []
};

export default PiMetadata;