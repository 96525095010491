import React from 'react';
import Header from '../../header';
import Title from '../../title';
import Metadata from '../../metadata/PiMetadataDetails';
import ReportEvents from '../../adverse-event';
import MainContent from '../../main-content';
import TOC from '../../toc';
import Pricing from '../../pricing';
import ContactDetails from '../../contact-details';
import Footer from '../../footer';
import PropTypes from 'prop-types';
import './style.scss';

function checkTOCHashtag() {

	let getURL = (window.location.href).split('#');

	if (getURL.length === 0) {
		return;
	}
	var URLHash = getURL[1];
	var ELEM = document.getElementById(URLHash);
	if (ELEM) {
		ELEM.scrollIntoView({});
	} else {
		setTimeout(function () {
			checkTOCHashtag();
		}, 1000);
	}

}

function adjustForEmc3Format(spcContent) {
	const htmlContainer = document.createElement('div');
	htmlContainer.innerHTML = spcContent;

	const paragraphs = htmlContainer.querySelectorAll('details.paragraph');

	paragraphs.forEach(function(paragraph) {
		paragraph.open = true;
		paragraph.classList.add('disabled');
		const summaryElement = paragraph.querySelector('summary');
		if (summaryElement) {
			summaryElement.classList.add('sectionHeaderTop');
		}
	});

	const subsections = htmlContainer.querySelectorAll('details:not(.paragraph)');

	subsections.forEach(function(subsection) {
		subsection.open = true;
		subsection.classList.add('disabled');
		const summaryElement = subsection.querySelector('summary');
		if (summaryElement) {
			summaryElement.classList.add('sectionHeader2ndLevel');
		}
	});

	return htmlContainer;
}

class PIPortalPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			pinContents: false,
			pageYPinDegree: 500,
		};
	}

	handleScroll(e) {

		this.setState({
			pageYPinDegree: (document.getElementById('solGovde').getBoundingClientRect().top
        + Math.max(document.documentElement.scrollTop || document.body.scrollTop) - 32),
			pinContents: window.pageYOffset >= this.state.pageYPinDegree
		});
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll.bind(this), true);
		window.addEventListener('load', checkTOCHashtag);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('load', checkTOCHashtag);
	}

	render() {
		const spcFile = adjustForEmc3Format(this.props.spcContent);

		let isBlackTriangle = false;

		if (this.props.spcContent != null) {
			isBlackTriangle = `${this.props.spcContent}`.includes('class="aisBlackTriangle"');
		}

		let contentClassName = 'content-area';
		if (this.state.pinContents) {
			contentClassName += ' pin-contents';
		}
		let adverseEventText = {
			__html: `${this.props.spcMetadata && this.props.spcMetadata.adverseEvent}`
		};
		let spcContent = {
			__html: spcFile.innerHTML
		};
		let tocContent = {
			__html: this.props.spcToc
		};
		let prices = this.props.spcMetadata && this.props.spcMetadata.price;

		return (
			<div className="pi-portal-page">
				<Header />
				<div className="app-page">
					<Title
						title={this.props.spcMetadata && this.props.spcMetadata.title}
						isBT={isBlackTriangle}

						//						isBT = {this.props.spcMetadata.length > 0}
						// isBT = {this.props.spcMetadata.includes('aisBlackTriangle')}
					/>
					<div className="top-area">
						<Metadata
							companyName={
								this.props.companyMetadata && this.props.companyMetadata.name
							}
							activeIngredient={
								this.props.spcMetadata &&
                this.props.spcMetadata.activeIngredient
							}
							legalCategory={
								this.props.spcMetadata && this.props.spcMetadata.legalCategory
							}
							smpclastUpdated={
								this.props.spcMetadata && this.props.spcMetadata.smpclastUpdated
							}
							pricelastUpdated={
								this.props.spcMetadata &&
                this.props.spcMetadata.pricelastUpdated
							}
						/>
						<ReportEvents htmlData={adverseEventText} />
					</div>
					<div id="solGovde" className={contentClassName}>
						<MainContent
							htmlData={spcContent}
							preview={this.props.preview}
							metadata={this.props.spcMetadata}
						/>
						<TOC htmlData={tocContent} />
					</div>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */}
					<a id="PRICE" className="anchor" />
					<Pricing prices={prices} />
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */}
					<a id="CONTACT" className="anchor" />
					<ContactDetails
						name={this.props.companyMetadata && this.props.companyMetadata.name}
						logoImgSrc={
							this.props.companyMetadata && this.props.companyMetadata.logoUrl
						}
						details={
							this.props.companyMetadata &&
              this.props.companyMetadata.contactDetail
						}
					/>
				</div>
				<Footer />
			</div>
		);
	}
}

PIPortalPage.defaultProps = {
	spcToc: PropTypes.object,
	spcMetadata: PropTypes.object,
	spcContent: PropTypes.object,
	companyMetadata: PropTypes.object
};

export default PIPortalPage;
