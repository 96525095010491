const months = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec'
];

module.exports = {
	/**
     * Get the full date in the expected format DD MM YYYY
     * 
     * @method getLastUpdatedDateFormat
     * @param {any} dateStr
     */
	getLastUpdatedDateFormat(dateStr) {
		function pad(number) {
			if (number < 10) {
				return '0' + number;
			}
			return number;
		}
		let date = new Date(dateStr);
		return pad(date.getUTCDate()) + ' ' + months[date.getUTCMonth()] + ' ' + date.getUTCFullYear();
	}
};