import React from 'react';
import classNames from 'classnames';

function Row({ children, align, wrap, justify, className, ...props }) {
	const cx = classNames({
		'flex-grid__row': true,
		'flex-grid--direction-row': true,
		'flex-grid--wrap': wrap,
		[`flex-grid--align-${align}`]: align && align !== '',
		[`flex-grid--justify-${justify}`]: justify && justify !== '',
		[`${className}`]: className && className !== ''
	});

	return (
		<div {...props} className={cx}>
			{children}
		</div>
	);
}

export default Row;