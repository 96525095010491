import React from 'react';
import './style.scss';

class TOC extends React.Component {
	constructor(props) {
		super(props);
		this.onWindowResize = this.onWindowResize.bind(this);
		this.isMediumOrGreater = this.isMediumOrGreater.bind(this);
		this.state = {
			showContents: this.isMediumOrGreater()
		};
     
		window.addEventListener('resize', this.onWindowResize);
	}

	isMediumOrGreater() {
		return window.innerWidth >= 768;
	}

	onWindowResize() {
		this.setState({ showContents: this.isMediumOrGreater() });
	}

	showHideClicked() {
		this.setState({ showContents: !this.state.showContents });
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onWindowResize);
	}

	render() {
		return (
			<div className='table-of-contents'>
				<div className="contents-container">
					<div
						className="show-hide-button"
						onClick={this.showHideClicked.bind(this)}>
						{this.state.showContents ? <h3>Hide Table of Contents</h3> : <h3>Show Table of Contents</h3>}
					</div>
					{
						this.state.showContents ?
							<React.Fragment >
								<div className="contents-list">
									<div className="toc-container" dangerouslySetInnerHTML={this.props.htmlData} />
									<div></div>
									<div style={{ marginTop:20 }}>
										<a href="#PRICE" title="Click to jump to section">Price</a>
										<br></br>
										<a href="#CONTACT" title="Click to jump to section">Contact Details</a>
									</div>
								</div>
								<div className="pointer-icon" />
							</React.Fragment> : null

					}
				</div>

			</div>
		);
	}
}

TOC.defaultProps = {
	htmlData: {
		__html: ''
	}
};

export default TOC;