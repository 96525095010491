import React from 'react';
import Header from '../../header';
import Footer from '../../footer';
import {Col, Grid, Row} from '../../grid';
import './style.scss';
import pillBox from '../../../assets/images/pill-box.png';

class PageNotFound extends React.Component {

	render() {
		return (
			<div className='pi-portal-page app-page page-not-found' style={{ height:'100vh' }}>
				<Header/>
				<div className="content">
					<Grid align="center">
						<Row wrap>
							<Col xs={4} sm={6} flex direction="column" align="center">
								<h2 className="title">We're sorry</h2>
								<div className="sub-content-message-line">
									<p>This page is currently unavailable and may 
										be being updated. Please come back later or contact the 
										Marketing Authorisation Holder for more details.
									</p>
								</div>
							</Col>
							<Col xs={4} sm={6} direction="column">
								<Row>
									<div className="image-container app-default-margin">
										<img src={pillBox} alt="empty medicines box"/>
									</div>
								</Row>
							</Col>
						</Row>
					</Grid>
				</div>
				<Footer/>
			</div>
		);
	}
}

PageNotFound.defaultProps = {};

export default PageNotFound;