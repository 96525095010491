import * as React from 'react';
import { GroupPageTabProps } from './GroupInterfaces';
import './style.scss';
import classnames from 'classnames';
import apiService from '../../../services/api-service';

const GroupPageTab: React.FC<GroupPageTabProps> = (props) => {
	const [activePiId, setActivePiId] = React.useState<number>(props.tab.piIds[0]?.piId);
	const [activeDocument, setActiveDocument] = React.useState<string>();
	const { setIsBlackTriangleValue } = props;
	const { setTocValue } = props;
	const { setPiMetadataValue } = props;

	React.useEffect(()=>{
		console.log(props.tab, props.tab.piIds, props.tab.piIds[0], props.tab.piIds[0]?.piId);
	},[props.tab])

	React.useEffect(() => {
		if (!activePiId) {
			return;
		} 

		props.tab?.piIds.map((pi) => pi.piId === activePiId && (
			setPiMetadataValue({
				...pi,
				companyName: props.companyName
			})
		));
		apiService.fetchData(`${props.path}piIds/${activePiId}/content.html`).then(res => {
			if (res) {
				setActiveDocument(adjustForEmc3Format(res.data).innerHTML);
			}
		});
		apiService.fetchData(`${props.path}piIds/${activePiId}/toc.html`).then(res => {
			if (res) {
				setTocValue(res.data);
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activePiId, props.path, props.tab]);
	
	React.useEffect(() => {
		if (activeDocument) {
			const isBT = `${activeDocument}`.includes('class="aisBlackTriangle"');
			setIsBlackTriangleValue(isBT);
		}
	}, [activeDocument, setIsBlackTriangleValue]);

	return (
		<div className="formTabContainer">
			{ activePiId && (
				<div className="buttonsContainer">
					{props.tab.piIds.map((pi, i) => (
						<button
							key={i}
							className={classnames('button', { active: pi.piId === activePiId })}
							onClick={() => setActivePiId(pi.piId)}
						>
							{pi.title.length > 100 ? `${pi.title.substring(0, 100)}...` : pi.title}
						</button>
					))}
				</div>
			)}
			{ props.preview &&
				<div className="noticePreview">
					THIS INFORMATION IS NOT LIVE. THIS IS A PREVIEW
				</div>
			}
			<div className="noticeTitle">
				This information is intended for use by health professionals
			</div>
			{ activeDocument
				? <div className='body' dangerouslySetInnerHTML={{ __html: `${activeDocument}`}} />
				: <div className='body'>loading...</div>
			}
		</div>
	);
};

function adjustForEmc3Format(spcContent: string) {
	const htmlContainer = document.createElement('div');
	htmlContainer.innerHTML = spcContent;

	const paragraphs = htmlContainer.querySelectorAll<HTMLDetailsElement>('details.paragraph');

	paragraphs.forEach(function(paragraph) {
		paragraph.open = true;
		paragraph.classList.add('disabled');
		const summaryElement = paragraph.querySelector('summary');
		if (summaryElement) {
			summaryElement.classList.add('sectionHeaderTop');
		}
	});

	const subsections = htmlContainer.querySelectorAll<HTMLDetailsElement>('details:not(.paragraph)');

	subsections.forEach(function(subsection) {
		subsection.open = true;
		subsection.classList.add('disabled');
		const summaryElement = subsection.querySelector('summary');
		if (summaryElement) {
			summaryElement.classList.add('sectionHeader2ndLevel');
		}
	});

	return htmlContainer;
}

export default GroupPageTab;
