import React from 'react';
import PIPortalPage from '../../pages/pi-portal-page';
import apiService from '../../../services/api-service';

class PageContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companyMetadata: null,
			spcContent: null,
			spcMetadata: null,
			spcToc: null
		};
	}

	componentDidMount() {
		const regionId = this.props.match && this.props.match.params && this.props.match.params.regionId;
		const piId = (this.props.match && this.props.match.params && this.props.match.params.piId) || 0;
		const spcId = (this.props.match && this.props.match.params && this.props.match.params.spcId) || 0;

		const dir = regionId ? 'piIds' : 'documents';
		const id = regionId ? piId : spcId;

		apiService.fetchData(`${this.props.path}${dir}/${id}/metadata.json`)
			.then(res => {
				if (res) {
					this.setState({ spcMetadata: res.data });
					let companyId = res.data.companyId;
					apiService
						.fetchData(
							`${this.props.path}${dir}/${id}/toc.html`
						)
						.then(res => {
							if (res) {
								this.setState({ spcToc: res.data });
							}
						});
					apiService
						.fetchData(
							`${this.props.path}${dir}/${id}/content.html`
						)
						.then(res => {
							if (res) {
								this.setState({ spcContent: res.data });
							}
						});
					apiService
						.fetchData(
							`${this.props.path}companies/${companyId}/metadata.json`
						)
						.then(res => {
							if (res) {
								this.setState({ companyMetadata: res.data });
							}
						});
				} else {
					this.props.history.push('/');
				}
			})
			.catch(() => {
				console.warn('no data found with id %s', id);
				this.props.history.push('/404');
			});
	}

	render() {
		return (
			<PIPortalPage
				preview={this.props.preview}
				spcToc={this.state.spcToc}
				spcMetadata={this.state.spcMetadata}
				spcContent={this.state.spcContent}
				companyMetadata={this.state.companyMetadata}
			/>
		);
	}
}

export default PageContainer;
