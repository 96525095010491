import * as React from 'react';
import classnames from 'classnames';
import './styles.scss';
import { PiMetadata } from '../../pages/group-page/GroupInterfaces';
import TOC from '../../toc';
import Metadata from '../../metadata/GroupMetadataDetails';

interface TabContainerProps {
	children: React.ReactElement[];
	tabs: { tabId: string; name: string; aeText: string }[];
	activeTab: string;
	tocData: string | undefined;
	piMetadata: PiMetadata | undefined;
	setActiveTab: (id: string) => void;
}

const TabContainer: React.FC<TabContainerProps> = (props) => {
	const { activeTab, setActiveTab } = props;

	return (
		<>
			<div className="adverseEventText">
				<h3 className="header">Adverse Event Reporting</h3>
				{ props.tabs.map((tab, i) => tab.tabId === activeTab &&
					<div className="text" key={i} dangerouslySetInnerHTML={{ __html: tab.aeText}} />
				)}
			</div>
			<div className="mainContent">
				<div className="rightSide">
					{props.piMetadata && 
						<Metadata
							companyName={props.piMetadata.companyName}
							activeIngredient={props.piMetadata.activeIngredient}
							legalCategory={props.piMetadata.legalCategory}
							smpclastUpdated={props.piMetadata.smpclastUpdated}
							pricelastUpdated={props.piMetadata.pricelastUpdated}
							reference={props.piMetadata.reference}
						/>
					}
					{props.tocData &&
						<div className="stickyToC">
							<TOC htmlData={{__html: props.tocData}} />
						</div>
					}
				</div>
				<div className="tabContainer">
					{props.tabs.map((tab, i) => 
						<div
							key={i}
							className={classnames('tab', { active: tab.tabId === activeTab })}
							onClick={() => setActiveTab(tab.tabId)} >
							<h5>{tab.name}</h5>
						</div>
					)}
					{props.children.map((child) => child.props.tabId === activeTab && child)}
				</div>
			</div>
		</>
	);
};

export default TabContainer;
