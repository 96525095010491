import React from 'react';
import {Col, Grid, Row} from '../grid';
import './style.scss';
import datapharmLogo from '../../assets/images/datapharm-logo.png';

class Footer extends React.Component {

	render() {
		let currentYear = new Date().getFullYear();
		return (
			<footer className='footer-component app-row-full'>
				
				<Grid>
					<Row wrap>
						<Col xs={4} sm={9} flex className="item-nav-links-holder text-group" align="flex-end">
							<ul className="nav-links">
								<li>
									<a
										href="https://www.datapharm.com/privacy-policy-for-datapharm-systems-users/"
										target="_blank"
										rel="noreferrer noopener">
									Privacy Policy
									</a>
								</li>
								<li>
									<a
										href="https://www.datapharm.com/cookie-policy/"
										target="_blank"
										rel="noreferrer noopener">
									Cookie Policy
									</a>
								</li>
							</ul>
						</Col>

						<Col xs={4} sm={12} className="item-hr-holder">
							<hr />
						</Col>

						<Col xs={4} sm={4} flex className="item-logo-holder" align="flex-end">

							<div className="datapharm-logo">
								<a className="footer-link logo-href"
									href="https://www.datapharm.com/" rel="noopener noreferrer" target="_blank">
									<img src={datapharmLogo} alt="datapharm logo" />
								</a>
							</div>

						</Col>

						<Col xs={4} sm={8} className="item-contact-details-holder">

							<Row className="contact-details">
								<Col xs={1} sm={2} lg={1}>
									<ul>
									</ul>
								</Col>
								<Col xs={3} sm={4}>
									<ul>
									</ul>
								</Col>
							</Row>
						</Col>

						<Col xs={4} sm={3} className="item-bottom-row-holder">
							<div className="bottom-row">
								<span className="copyright-text">{'\u00a9'} Datapharm Ltd {currentYear}</span>
							</div>
						</Col>
					</Row>
				</Grid>

			</footer>

		);
	}

}

export default Footer;