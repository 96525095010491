import React from 'react';
import MainLogo from '../../assets/images/pi_logo_u4.png';
import './style.scss';

class Header extends React.Component {

	render() {
		return (
			<header className="main-header">
				<div className="main-logo">
					<img src={MainLogo} alt="PI Portal Logo"  />
				</div>
			</header>

		);
	}

}

export default Header;