import React from 'react';
import classNames from 'classnames';

function Col({ children, grow, noPad, direction, shrink, align, xs, sm, md, lg, xl, flex, className, ...props }) {
	const cx = classNames({
		'flex-grid__col': true,
		'flex-grid__col--grow': grow,
		'flex-grid__col--no-pad': noPad,
		'flex-grid__col--shrink': shrink,
		'flex-grid__col--flex': flex,
		[`flex-grid--direction-${direction}`]: direction && direction !== '',
		[`flex-grid__col--align-${align}`]: align && align !== '',
		[`flex-grid__col--xs-${xs}`]: xs,
		[`flex-grid__col--sm-${sm}`]: sm,
		[`flex-grid__col--md-${md}`]: md,
		[`flex-grid__col--lg-${lg}`]: lg,
		[`flex-grid__col--xl-${xl}`]: xl,
		[`${className}`]: className && className !== ''
	});

	return (
		<div {...props} className={cx}>
			{children}
		</div>
	);
}

export default Col;