import apiService from '../../../services/api-service';

export async function fetchGorupMetadata (groupId, previewMode) {
  const urlPartial = previewMode ? 'groupsPreview' : 'groups';

  const groupMetadataRequest = await apiService.fetchData(`${process.env.REACT_APP_CDN_BASEPATH}${urlPartial}/${groupId}/metadata.json`);
  const piData = await Promise.all(groupMetadataRequest.data.tabs.map((tab)=>Promise.all(
    tab.piIds.map((piId)=>apiService.fetchData(`${process.env.REACT_APP_CDN_BASEPATH}piIds/${piId}/metadata.json`))
  )));

  piData.forEach((pi, i)=>{
    groupMetadataRequest.data.tabs[i].piIds = pi.reduce((acc, e)=>{
      if (e) {
        acc.push(e.data);
      }
      return acc;
    },[]);

  })

  return groupMetadataRequest.data;
}

export async function fetchCompanyMetadata (companyId) {
  const response = await apiService.fetchData(`${process.env.REACT_APP_CDN_BASEPATH}companies/${companyId}/metadata.json`);
  return response.data;
}