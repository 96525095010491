import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import './style.scss';

class ReportEvents extends React.Component {

	render() {
		return (
			<div className='adverse-event'>
				<h3 className="ae-title">Adverse Event Reporting</h3>
				<div className="ae-text" dangerouslySetInnerHTML={this.props.htmlData}/>
			</div>
		);
	}
}

ReportEvents.defaultProps = {
	htmlData: {
		__html: ''
	}
};

export default ReportEvents;