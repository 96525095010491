import React from 'react';
import './style.scss';

class ContactDetails extends React.Component {

	render() {
		return (
			<div id='contact_details' className='contact-details-component'>
				<div className="component-title">
					<h2>Further information on request from</h2>
				</div>
				<div className="company-metadata">
					<div className="block-spacing">
						<h3>
							{this.props.name}
						</h3>
						<div className="name-and-logo">
							<img src={this.props.logoImgSrc} alt="pharma company logo" />
						</div>
					</div>
					<div className="details">
						<ul>
							{
								this.props.details && this.props.details.map((data, i) => {
									return <li key={i}>
										<h4>{data.title}</h4>
										<p>
											{data.value}
										</p>
									</li>;
								})
							}
						</ul>
					</div>
				</div>
			</div>
		);
	}

}

ContactDetails.defaultProps = {
	name: '',
	logoImgSrc: '',
	details: []
};

export default ContactDetails;