import React from 'react';
import Header from '../../header';
import Title from '../../title';
import ContactDetails from '../../contact-details';
import Footer from '../../footer';
import './style.scss';
import TabContainer from '../../containers/tab-container/TabContainer';
import GroupPageTab from './GroupPageTab';
import { GroupPageProps, PiMetadata } from './GroupInterfaces';
import Pricing from '../../pricing';

const GroupPage: React.FC<GroupPageProps> = (props) => {
	const [isBlackTriangleValue, setIsBlackTriangleValue] = React.useState(false);
	const [activeTab, setActiveTab] = React.useState<string>();
	const [tocValue, setTocValue] = React.useState<string>();
	const [piMetadataValue, setPiMetadataValue] = React.useState<PiMetadata>();

	React.useEffect(() => {
		if (props.groupMetadata && props.groupMetadata.tabs.length && !activeTab) {
			setActiveTab(props.regionId ? props.regionId.toUpperCase() : props.groupMetadata.tabs[0].regionId);
		}
	}, [props.groupMetadata, props.regionId, activeTab]);

	return (
		<div className="pi-portal-page">
			<Header />
			<div className="app-page">
				<Title title={props.groupMetadata?.title} isBT={isBlackTriangleValue} />
				{props.companyMetadata && props.groupMetadata?.tabs && activeTab && (
					<TabContainer
						activeTab={activeTab}
						tocData={tocValue}
						piMetadata={piMetadataValue}
						setActiveTab={(id) => setActiveTab(id)}
						tabs={props.groupMetadata?.tabs?.map((tab) => ({ name: tab.title, tabId: tab.regionId, aeText: tab.adverseEvent }))}
					>
						{props.groupMetadata?.tabs?.map((tab) => 
							<GroupPageTab 
								key={tab.regionId}
								tabId={tab.regionId}
								tab={tab}
								preview={props.preview}
								path={props.path}
								companyName={props.companyMetadata.name}
								setIsBlackTriangleValue={(id) => setIsBlackTriangleValue(id)}
								setTocValue={(toc) => setTocValue(toc)}
								setPiMetadataValue={(piMetadata) => setPiMetadataValue(piMetadata)} />
						)}
					</TabContainer>
				)}
			
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */}
				<a id="PRICE" className="anchor" />
				{piMetadataValue?.price && <Pricing prices={piMetadataValue.price} />}

				<ContactDetails
					name={props.companyMetadata?.name}
					logoImgSrc={props.companyMetadata?.logoUrl}
					details={props.companyMetadata?.contactDetail}
				/>
			</div>
			<Footer />
		</div>
	);
};

export default GroupPage;
