import React from 'react';
import GroupPage from '../../pages/group-page/GroupPage';
import { fetchCompanyMetadata, fetchGorupMetadata } from './GroupContainerService';

class GroupContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companyMetadata: null,
			groupMetadata: null,
			regionId: null
		};
	}

	fetchData = async () => {
		const groupId = this.props.match?.params?.groupId || 0;

		const groupMetadata = await fetchGorupMetadata(groupId, this.props.preview);
		const companyMetadata = await fetchCompanyMetadata(groupMetadata.companyId);

		this.setState({ groupMetadata, companyMetadata, regionId: groupMetadata.tabs[0].regionId});
	}
	
	componentDidMount() {
		this.fetchData();
	}

	render() {
		return <GroupPage {...this.props} {...this.state} />;
	}
}

export default GroupContainer;
