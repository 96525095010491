import React from 'react';
import './style.scss';

class MainContent extends React.Component {

  render() {
  	return (
  		<div className="main-content-column">
  			<div className="smpc-title">
  				<h2>SmPC</h2>
  			</div>
  			<div className="main-content" style={{ overflowX: 'auto' }}>
  				<div className="notice-outer">
  					{
  						this.props.preview ?
  							<div style={{
  								backgroundColor: 'red', textAlign: 'center',
  								lineHeight: 2, color: 'white', padding: 20, marginBottom: 10
  							}}>
  								<strong>THIS INFORMATION IS NOT LIVE. THIS IS A PREVIEW</strong></div>
  							: null
  					}
  					<p id="PRODUCTINFO" className="notice-inner">
  						<strong className="title-style">
  							{/* eslint-disable-next-line max-len */}
  							{`This information is intended for use by health professionals`}
  						</strong>
  					</p>
  				</div>
  				<div dangerouslySetInnerHTML={this.props.htmlData} />
  			</div>
  		</div>

  	);
  }
}

MainContent.defaultProps = {
	htmlData: {
		__html: ''
	}
};

export default MainContent;
