import React from 'react';
import './scss/app.scss';
import PIPortalPageContainer from './components/containers/pi-portal-page-container';
import PIPortalGroupContainer from './components/containers/pi-portal-group-container/GroupContainer';
import PageNotFound from './components/pages/page-not-found';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
	return (
		<Router>
			<div className="app">
				<Switch>
					<Route exact path={['/pi/:spcId', '/pi/:regionId/:piId']}
						component={(params) => <PIPortalPageContainer path={`${process.env.REACT_APP_CDN_BASEPATH}`} preview={false} {...params} />} />
					<Route exact path={['/previewpi/:spcId', '/previewpi/:regionId/:piId']}
						component={(params) => <PIPortalPageContainer path={`${process.env.REACT_APP_CDN_BASEPATH}preview/`} preview={true} {...params} />} />
					<Route exact path="/" component={PageNotFound}/>
					<Route exact path={['/grp/:groupId']}
						component={(params) => <PIPortalGroupContainer path={`${process.env.REACT_APP_CDN_BASEPATH}`} preview={false} {...params} />} />
					<Route exact path={['/previewgrp/:groupId']}
						component={(params) => <PIPortalGroupContainer path={`${process.env.REACT_APP_CDN_BASEPATH}`} preview={true} {...params} />} />
					<Route exact path={['/grp/:groupId/:regionId']}
						component={(params) => <PIPortalGroupContainer path={`${process.env.REACT_APP_CDN_BASEPATH}`} preview={false} {...params} />} />
					<Route exact path={['/previewgrp/:groupId/:regionId']}
						component={(params) => <PIPortalGroupContainer path={`${process.env.REACT_APP_CDN_BASEPATH}`}  preview={true} {...params} />} />
					<Route path="*" component={PageNotFound} />
				</Switch>
			</div>
		</Router>
	);
}

export default App;
