import classNames from 'classnames';
import React from 'react';

function Grid({ children, align, justify, gutter, className, ...props }) {
	const cx = classNames({
		'flex-grid': true,
		'grid-container': true,
		'flex-grid--direction-column': true,
		'flex-grid--wrap': true,
		[`flex-grid--align-${align}`]: align && align !== '',
		[`flex-grid--justify-${justify}`]: justify && justify !== '',
		[`${className}`]: className && className !== ''
	});

	return (
		<div {...props} className={cx}>
			{children}
		</div>
	);
}

export default Grid;